const __request = require(`./__request/flight-admin/__request_contentType_json`);

// 航空公司查询
// 接口文档地址：http://183.58.24.99:9999/swagger-ui.html?tdsourcetag=s_pcqq_aiomsg#/
const change = (pParameter) => {
  // if (!pParameter) pParameter = {};
  // const data = {...pParameter}
  let data = {
    method: 'post',
    // urlSuffix: '/flight/domestic/buyer/white/order/change',
    urlSuffix:'/buyer/dom/white/change',
    data: pParameter,
    enable_error_alert: false // 临时隐藏
  }
  console.log(data)
  return __request(data)
}
module.exports = change;
