//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// 订单查询
const detail = require(`@/lib/data-service/flight/flight_domestic_buyer_manage_order_detail`);
const change = require(`@/lib/data-service/flight/flight_domestic_buyer_white_order_change`);
  export default {
    data () {
      return {
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() < Date.now() - 1000 * 3600 * 24;
          },
          shortcuts: [
            {
              text: '今天',
              onClick(picker) {
                picker.$emit('pick', new Date());
              }
            }
          ]
        },
        detailData: null,
        orderNo: '',
        orderStatus: '',
        beginDate1: '',
        beginDate2: '',
        orderStatusText: '',
        flightType: '1',
        passengers: [],
        changeType: 1,
        dishonourId1: [],
        dishonourId2: [],
        cabinCode1: '',
        cabinCode2: '',
        remark: ''
      }
    },
    methods: {
      // GetdetailData () {
      //   detail({orderNo: this.orderNo}).then( res => {
      //     console.log(res)
      //     this.detailData = res.detail
      //     this.flightType = res.detail.flightType
      //     this.$forceUpdate();
      //   })
      // }
      getsegments(id,inputType) {
        inputType.inputC = !inputType.inputC
        console.log(id,inputType)
        this.$forceUpdate();
      },
      getName(item) {
        // console.log(item)
        item.inputC = !item.inputC
        for (let i = 0; i < item.segments.length; i++) {
          if(item.segments[i].status === '1' || item.segments[i].status === '2') {
            item.segments[i].inputC = item.inputC
            this.$forceUpdate();
          }
        }
      },
      getDishonour () {
        if(this.changeType === '请选择') {
          return this.$message({
            message: "请选择退票类型",
            type: "error"
          });
        }
        if (this.changeType === 1 && this.beginDate === '') {
          return this.$message({
            message: "请填写改签日期",
            type: "error"
          });
        }
        if (this.changeType === 2 && this.cabinCode === '') {
          return this.$message({
            message: "请填写改签舱位",
            type: "error"
          });
        }
        this.dishonourId1 = []
        this.dishonourId2 = []
        for (let i = 0; i < this.passengers.length; i++) {
          for (let j = 0; j < this.passengers[i].segments.length; j++) {
            if(this.passengers[i].segments[j].inputC){
                let status = this.passengers[i].segments[j].status
                let type = this.passengers[i].segments[j].type
                type==1&&(status==1||status==2)?
                this.dishonourId1.push(this.passengers[i].segments[j].airlineId):
                this.dishonourId2.push(this.passengers[i].segments[j].airlineId)
            }
            // if(this.passengers[i].segments[j].inputC === true ) {
            //    this.dishonourId1.push(this.passengers[i].segments[j].airlineId)
            // }
            // if(this.passengers[i].segments[j].inputC === true && this.passengers[i].segments[j].flightType === '2'){
            //    this.dishonourId2.push(this.passengers[i].segments[j].airlineId)
            // }
          }
        }
        // if(this.dishonourId1.length === 0) {
        //   return this.$message({
        //     message: "请选择需要改签的乘客",
        //     type: "error"
        //   });
        // }
        // let lineId1 = this.dishonourId1.join()
        // let lineId2 = this.dishonourId2.join()
        console.log(this.dishonourId1,this.dishonourId2)
        let params = {
          remark: this.remark,
          orderNo: this.orderNo
        }
        if(this.flightType==1){
              if(this.dishonourId1.length==0)return this.$message({type:"warning",message:"请选择乘客"})
               params.changes = [{
                  beginDate: this.beginDate1,
                  cabinCode: this.cabinCode1,
                  changeType: this.changeType,
                  segmentIds: this.dishonourId1.join(),
               }]
        }else if(this.flightType==2){
             if(this.dishonourId2.length==0)return this.$message({type:"warning",message:"请选择乘客"})
             let arr = []
             let _a = {}
             let _b = {}
             this.dishonourId1.length>0?
             (_a.segmentIds = this.dishonourId1.join(),
             _a.beginDate = this.beginDate1,
             _a.cabinCode = this.cabinCode1,
             _a.changeType = this.changeType,
             arr.push(_a)):''
             this.dishonourId2.length>0?
             (_b.segmentIds = this.dishonourId2.join(),
             _b.beginDate = this.beginDate2,
             _b.cabinCode = this.cabinCode2,
             _b.changeType = this.changeType,
             arr.push(_b)):''
             params.changes = arr
            // if(!this.beginDate2||!this.beginDate1)return this.$message({type:"warning",message:"请选择往返时间"})
        }
        // let params = {
        //   changes: [
        //     {
        //       beginDate: this.beginDate1,
        //       cabinCode: this.cabinCode1,
        //       changeType: this.changeType,
        //       segmentIds: lineId1,
        //     },
        //     {
        //       beginDate: this.beginDate2,
        //       cabinCode: this.cabinCode2,
        //       changeType: this.changeType,
        //       segmentIds: lineId2,
        //     },
        //   ],
        //   remark: this.remark,
        //   orderNo: this.orderNo
        // }
        // console.log(params)
        console.log('123',params)
        change(params).then(res => {
          console.log(res)
          this.$router.go(-1);
        }).catch(err => {
          return this.$message({
            message: err,
            type: "error"
          });
        })
      }
    },
    created () {
      if (this.$route.query.ordersNo) {
        this.orderNo = this.$route.query.ordersNo
        this.orderStatus = this.$route.query.orderStatus
        this.orderStatusText = this.$route.query.orderStatusText
        detail({orderNo: this.orderNo}).then( res => {
          console.log(res)
          this.detailData = res.detail
          this.flightType = res.detail.flightType
          this.passengers = res.detail.passengers
          for (let i = 0; i < this.passengers.length; i++) {
            this.passengers[i].inputC = false
            for (let j = 0; j < this.passengers[i].segments.length; j++) {
              this.passengers[i].segments[j].inputC = false
            }
          }
          this.$forceUpdate();
        })
      }
    }
  }
